<!-- 查询 -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>分销订单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
<!--        <el-col :span="3">-->
<!--          <el-button type="primary" @click="exportOrderExcel"-->
<!--            >导出Excel<i class="el-icon-upload el-icon&#45;&#45;right"></i>-->
<!--          </el-button>-->
<!--        </el-col>-->
        <el-col :span="3">
          <el-button type="primary" @click="clearing"
          >批量结算<i class="el-icon-check el-icon--right"></i>
          </el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- 卡片视图 -->

    <el-card>
      <!-- 搜索框 -->
      <el-row :gutter="12">
        <el-col :span="4">
          <el-input
            placeholder="请输入订单号"
            clearable
            v-model="queryInfo.orderNo"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getOrderList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            type="date"
            placeholder="选择起始日期"
            v-model="queryInfo.startTime"
            @change="getOrderList"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            type="date"
            placeholder="选择截止日期"
            v-model="queryInfo.endTime"
            @change="getOrderList"
          ></el-date-picker>
        </el-col>
        <!-- <el-col :span="3">
          <el-select v-model="queryInfo.orderType" clearable placeholder="支付方式" @change="getOrderList()">
            <el-option v-for="item in orderTypeOptions" :key="item.Value" :label="item.Key" :value="item.Value">
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span="3" v-if="cooperType!='6'">
          <el-select
            v-model="queryInfo.qudaoType"
            clearable
            placeholder="渠道"
            @change="getOrderList"
          >
            <el-option
              v-for="(item, index) in qudaoTypeList"
              :key="index"
              :label="item.name"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="queryInfo.use_status"
            clearable
            placeholder="订单状态"
            @change="getOrderList"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
              v-model="queryInfo.status"
              clearable
              placeholder="结算状态"
              @change="getOrderList"
          >
            <el-option label="未结算" :value="0"></el-option>
            <el-option label="已结算" :value="1"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- 订单列表 -->
      <el-table :data="orderList" border stripe @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column label="渠道类型" prop="qudaoTypeName"></el-table-column>
        <el-table-column label="渠道" prop="qudaoName"></el-table-column>
        <el-table-column label="订单号" prop="orderno"></el-table-column>
        <el-table-column label="票名" prop="ticket_name"></el-table-column>
        <el-table-column label="数量" prop="number"></el-table-column>
        <el-table-column label="使用时间" prop="use_time"></el-table-column>
        <el-table-column label="分成方式" prop="fenxiao_type">
          <template slot-scope="scope">
            {{scope.row.fenxiao_type==='percent'?'提成':'计数'}}
          </template>
        </el-table-column>
        <el-table-column label="分成基数" prop="fenxiao_base"></el-table-column>
        <el-table-column label="使用时间" prop="use_time"></el-table-column>
        <el-table-column label="结算状态" prop="status">
          <template slot-scope="scope">
            {{scope.row.status===0?'未结算':'已结算'}}
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="分成" prop="fenxiao_money"></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status===0"
              type="success"
              size="mini"
              @click="writeOffOrder(scope.row)"
              >结算</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        :page-sizes="page_sizes"
        layout="sizes,total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>


  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";
import {
  getReportByWay,
  getReportByQuDao,
  qudaoTypeList,
  qudaoList,
  qudaoByid,
} from "@/network/channel.js";

export default {
  name: "order",
  data() {
    return {
      queryInfo: {
        searchStr: "",
        // 当前的页数
        currentPage: 1,
        pageSize: 10,
        status: "",
        startTime: "",
        endTime: "",
        userId: "",
        orderType: "",
        qudaoType: "",
        use_status:''
      },
      page_sizes:[10, 20, 50],
      total: 0,
      showInfoDialogVisible: false,
      showTodayMoneyVisible: false, //今日报表框
      orderList: [],
      statusOptions: [
        {
          //   value: '0',
          //   label: '未支付'
          // }, {
          value: 1,
          label: "已支付",
        },
        {
          value: 3,
          label: "已退款",
          // }, {
          //   value: '4',
          //   label: '已关闭'
        },
      ],
      orderDetailInfo: [],
      orderDetailPage: 1,
      orderTypeOptions: [],
      sellerOptions: [],
      goodsTotal: 0,
      isAdmin: window.sessionStorage.getItem("isAdmin"),
      companyId: window.sessionStorage.getItem("companyId"),
      // 当天收入报表
      todayMonery: [],
      // 格式化后的当天报表数据
      formatMonery: {},
      formatMoneryKeys: [],
      // 总收入
      totalIncome: 0,
      // 选择报表日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() + 24 * 60 * 60 * 1000;
        },
      },

      // 选择报表日期-时间戳
      dateSelect: "",
      // 渠道mode显示
      showTodayQudaoVisible: false,
      // 渠道数据
      qudaoTypeList: [],
      // 渠道日期内的数据
      qudaoTodayList: [],
      // 选择渠道日期
      dateQudaoSelect: [
        `${new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}`,
        `${new Date()}`,
      ],
      // 选择渠道日期-时间戳
      dateQudaoSelectTime: [],
      // 选择渠道名
      activeQudaoNameType: "",
      // 获取渠道数据传的参数
      qudaoTypeParam: {},
      // 当前渠道销售数据
      acticveQudaoData: [],
      // 格式化订单数据
      selectedSeatList: [],
      // 渠道类型下的渠道
      qudaoDataList: [],
      // 选中的渠道
      activeQudaoName: "",
      cooperType:0,
      activeList:[]
    };
  },
  created() {
    this.getOrderList();
    this.getQudaoTypeList();
    this.cooperType=window.sessionStorage.getItem('cooperType')||0
  },
  computed: {},
  methods: {
    // // 合并单元格
    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   console.log('row, column, rowIndex, columnIndex: ', row, column, rowIndex, columnIndex);
    //   if (columnIndex === 0) {
    //     if (rowIndex % 2 === 0) {
    //       return {
    //         rowspan: 2,
    //         colspan: 2,
    //       };
    //     } else {
    //       return {
    //         rowspan: 0,
    //         colspan: 0,
    //       };
    //     }
    //   }
    // },
    handleSelectionChange(e){
      this.activeList=e.filter(v=>v.status===0)
    },
    goReport(){
      this.$router.push({
        path: "/message/reportjy",
      });
    },
    // 添加总计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 2) {
            sums[index] += " 次";
          } else {
            sums[index] += " 元";
          }
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },

    // 选择日期
    selsectDate(e) {
      console.log("选了时间: ", e);
      let date = new Date(e).getTime();
      this.dateSelect = date;
      console.log("selsectDate: ", this.dateSelect);
      this.getReportByWayData();
    },
    // 渠道选择日期
    selectDateQudao() {
      this.getReportByQuDaoData();
    },

    // 选择渠道类型
    selectQudaoType(tag) {
      console.log("tag: ", tag);
      let that = this
      this.qudaoDataList = [];
      this.activeQudaoName = "";
      this.qudaoTypeParam.typeName = tag;
      this.getReportByQuDaoData();
      console.log(' this.qudaoTypeList: ',  this.qudaoTypeList);

      for (let i = 0; i <  this.qudaoTypeList.length; i++) {
        if(this.qudaoTypeList[i].name == tag){
          that.getQudaoList( this.qudaoTypeList[i].ID);
        }
      }

    },

    // 获取渠道
    async getQudaoList(id) {
      this.qudaoDataList = []
      let res = await qudaoByid({ id });
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.qudaoDataList = res.data;
      }
    },

    // 选择渠道
    selectQudao(tag) {
      console.log("tag: ", tag);
      this.qudaoTypeParam.qudao = tag;
      this.getReportByQuDaoData();
    },

    // start,end,typeName
    // 获取渠道销售额
    async getReportByQuDaoData() {
      let that = this;
      this.dateQudaoSelect.map((item, index) => {
        that.dateQudaoSelect[index] = new Date(item).getTime();
      });
      this.qudaoTypeParam.start = this.dateQudaoSelect[0];
      this.qudaoTypeParam.end = this.dateQudaoSelect[1];
      this.qudaoTodayList = [];

      let res = await getReportByQuDao(this.qudaoTypeParam);
      console.log("getReportByQuDaores: ", res);
      if (res.data && res.data.length > 0) {
        this.qudaoTodayList = res.data;
        console.log("this.qudaoTodayList: ", this.qudaoTodayList);
        // this.fromatQudaoTodayData();
      }
    },
    // 格式化渠道报表数据
   async fromatQudaoTodayData() {
      // 添加id
      for (let i = 0; i < this.qudaoTypeList.length; i++) {
        for (let j = 0; j < this.qudaoTodayList.length; j++) {
          if (
            this.qudaoTypeList[i].name == this.qudaoTodayList[j]["渠道类型"]
          ) {
            this.qudaoTodayList[j].ID = this.qudaoTypeList[i].ID;
          }
        }
      }
      this.qudaoTodayList.forEach(async (item, index) => {
         await this.getQudaoList(item.ID)
         item['渠道名称'] = this.qudaoDataList
      });
      console.log("this.qudaoTodayList: ", this.qudaoTodayList);
    },


    // 获取渠道
    async getQudaoTypeList() {
      let res = await qudaoList();
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.qudaoTypeList = res.data;
      }
    },

    exportOrderExcel() {
      let that = this;
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const name = `订单列表`;
      let queryInfo = {};
      if (this.queryInfo.startTime) {
        queryInfo.startTime = new DateFormat(
          new Date(this.queryInfo.startTime)
        ).toString("yyyy-0m-0d");
      }

      if (this.queryInfo.endTime) {
        queryInfo.endTime = new DateFormat(
          new Date(this.queryInfo.endTime)
        ).toString("yyyy-0m-0d");
      }
      if (this.queryInfo.orderType) {
        queryInfo.orderType = this.queryInfo.orderType;
      }
      if (this.queryInfo.status) {
        queryInfo.status = this.queryInfo.status;
      }
      if (this.queryInfo.orderNo) {
        queryInfo.orderNo = this.queryInfo.orderNo;
      }
      if (this.isAdmin === "0") {
        console.log(
          "isAdmin",
          this.isAdmin,
          window.sessionStorage.getItem("userId")
        );
        queryInfo.userId = window.sessionStorage.getItem("userId");
      }

      console.log("queryInfo: ", queryInfo);
      console.log("that.queryInfo", that.queryInfo);

      that
        .$axios({
          url: `/manager/exportOrderExcel`,
          params: queryInfo,
          data: {
            data: that.queryInfo,
          },
          method: "GET",
          responseType: "blob",
        })
        .then(function (data) {
          if (!data) {
            return;
          }
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", name + ".xlsx");

          document.body.appendChild(link);
          link.click();

          loading.close();
          that.$message({
            type: "success",
            message: "正在导出报表...",
          });
        })
        .catch(function (err) {
          console.log(err);

          loading.close();
        });
    },
    getOrderList() {
      if (this.queryInfo.startTime) {
        this.queryInfo.startTime = new DateFormat(
          new Date(this.queryInfo.startTime)
        ).toString("yyyy-0m-0d");
      }

      if (this.queryInfo.endTime) {
        this.queryInfo.endTime = new DateFormat(
          new Date(this.queryInfo.endTime)
        ).toString("yyyy-0m-0d");
      }

      if (this.isAdmin === "0") {
        console.log(
          "isAdmin",
          this.isAdmin,
          window.sessionStorage.getItem("userId")
        );
        this.queryInfo.userId = window.sessionStorage.getItem("userId");
      }

      this.$axios({
        method: "get",
        url: "/manager/fenXiaoList",
        params: this.queryInfo,
      })
        .then((res) => {
          this.orderList = res.data.map((v) => {
            // v.购买时间=subHour(v.购买时间)
            // if(v.验证时间&&v.验证时间!=='无'){
            //   v.验证时间=subHour(v.验证时间)
            // }
            return v;
          });
          this.page_sizes[3]=res.total
          this.total = res.total;
        })
        .catch((err) => err);

    },
    handleSizeChange(e){
      this.queryInfo.pageSize=e
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getOrderList();
    },
    orderDetail(id) {
      console.log(id);
      this.$axios({
        method: "get",
        url: "/manager/orderDetail",
        params: {
          orderId: id,
          pageNum: this.orderDetailPage,
        },
      })
        .then((res) => {
          // console.log(res.data);
          if (res.code == 200) {
            this.orderDetailInfo = res.data;
            this.showInfoDialogVisible = true;
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch((err) => err);
    },
    clearing(){
      this.writeOffOrder(this.activeList.map(v=>v.ID))
    },
    writeOffOrder(row) {
      let that = this;
      this.$confirm("请确认要进行结算吗？", "分销结算", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = that.$loading({
            lock: true,
            text: "结算中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$axios({
            method: "post",
            url: "/manager/clearingFenXiao",
            data: {
              id_list: Array.isArray(row)?row:[row.ID]
            },
          }).then((res) => {
            loading.close();
            if (res.code === 200) {
              that.$message.success("结算成功");
              that.getOrderList();
            } else {
              that.$message.warning(res.data);
            }
          });
        })
        .catch(() => {
          console.log("用户点击取消");
        });
    },
    closeOrder(row) {
      let that = this;
      this.$confirm("请确认要废弃该订单吗？", "确认废弃", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = that.$loading({
            lock: true,
            text: "请求中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$axios({
            method: "post",
            url: "/order/closeOrder",
            data: {
              orderid: row.订单号,
            },
          }).then((res) => {
            loading.close();
            if (res.code === 200) {
              that.$message.success("废弃成功");
              that.getOrderList();
            } else {
              that.$message.warning(res.data);
            }
          });
        })
        .catch(() => {
          console.log("用户点击取消");
        });
    },
    handleClick(row) {},
    printTicket(_order) {
      let order=JSON.parse(JSON.stringify(_order))
      console.log("order: ", order);
      let that = this;
      const loading = that.$loading({
        lock: true,
        text: "正在生成票据",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        order.购买时间 = order.购买时间.replace(/[年月日时分]/g, function (a) {
          console.log(a);
          if (["日", "分"].includes(a)) {
            return "";
          } else if (["年", "月"].includes(a)) {
            return "-";
          } else {
            return ":";
          }
        });
        let date = new DateFormat(new Date(order.购买时间)).toString(
          "yyyy-0m-0d"
        );
        console.log("orderDetailInfo:", this.orderDetailInfo);
        console.log("开始打印票据", date);
        let method = order.是否取票;
        let personType = order.是否团队票;
        console.log("开始请求详情");
        this.$axios({
          method: "get",
          url: "/manager/orderDetail",
          params: {
            orderId: order.ID,
            pageNum: that.orderDetailPage,
          },
        })
          .then((res) => {
            console.log("res========: ", res);
            if (res.code == 200) {
              order["订单明细"] = res.data.订单明细;
              order["订单座位"] = res.data.订单座位;
              // order = [order];
              console.log("orderDetail====res: ", order);
              let path = "/message/ticket";
              let data = order.省;
              if(!data){
                data=new DateFormat(
                    new Date(order["预计使用时间"].replace(/年|月|日/g, '/'))
                ).toString("yyyy/mm/dd")+' 20:00';
              }
              data = this.timestampToDate(data);

              data = data.split(" ");
              console.log("data: ", data);
              order.qrUrl = that.genrateERCodeURL(order.订单号);
              console.log("开始生成二维码", order.qrUrl);
              order.订单座位.forEach((item, index) => {
                that.selectedSeatList.push({
                  区域名: item.区域名,
                  名称: item.名称,
                  价格信息: [
                    {
                      原价: "198.00",
                    },
                  ],
                });
              });
              console.log("this.selectedSeatList: ", this.selectedSeatList);

              loading.close();
              that.$router.push({
                path,
                query: {
                  printInfo: this.selectedSeatList,
                  date: data,
                  time: "65",
                  orderNum: order.订单号,
                  ticketType: order.区,
                },
              });
            } else {
              loading.close();
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            loading.close();
          });
      } catch (e) {
        loading.close();
      }
    },

    goPage(page) {
      console.log(page);
      this.$router.push(page);
    },
    genrateERCodeURL(orderId) {
      return `https://test-pyssp.iat.wang/newminipro?type=check&ID=${orderId}&t=${new Date().getTime()}`;
    },
    timestampToDate(dateStr) {
      let dateStr1=dateStr.split(" ")
      var dateParts
      if (dateStr1[0].indexOf('/')!==-1){
        dateParts = dateStr1[0].split("/");
      }else {
        dateParts = dateStr1[0].split("-");
      }
      var timeParts = dateStr1[1].split(":");
      var date = new Date(
        dateParts[0],
        dateParts[1] - 1,
        dateParts[2],
        timeParts[0],
        timeParts[1]
      );

      let year = date.getFullYear() + "年";
      let month = ("0" + (date.getMonth() + 1)).slice(-2) + "月";
      let day = ("0" + date.getDate()).slice(-2) + "日 ";
      let hour = date.getHours() + "点";
      let minute = ("0" + date.getMinutes()).slice(-2) + "分";

      return year + month + day + hour + minute;
    },
  },
  filters: {
    getPayType(typeID,way='') {
      console.log(typeID,way)
      switch (typeID) {
        case "1":
          return "其他三方支付";
        case "2":
          return "现金支付";
        case "3":
          return "微信支付";
        case "4":
          return "支付宝支付";
        case "5":
          return "银联支付";
        case "6":
          return "OTA支付";
        case "7":
          return "VM";
        case "8":
          return "对公汇款";
        case "9":
          return "欠款";
        default:
          return way
          break;
      }
    },
    filtersFormatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
  },
};
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  /* border: 1px solid gray; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  flex-shrink: 0;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.money-table {
  width: 100%;
  border: 1px solid #ebeef5;
  border-collapse: collapse;
}

.money-table-row {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.money-table-row > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  min-height: 48px;
}

.table-row-two {
  height: 100%;
  border: none !important;
  flex: 1;
}

.row-two-item {
  flex: 1;
}

.row-two-item > div {
  min-height: 28px;
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-two-item > :last-child {
  border-bottom: none !important;
}

/**改变边框颜色*/
/* .el-table {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  margin: 0 auto;
}
:deep.el-table th {
  border: 1px solid #000;
  border-right: none;
  border-bottom: none;
}
:deep.el-table td {
  border: 1px solid #000;
  border-right: none;
  border-bottom: none;
} */
/**改变表头标题颜色*/
/* :deep.el-table thead {
    color: #000;
} */
</style>
